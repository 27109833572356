body {
    background-color: #ffffff !important;
}

a {
    color: #16355e !important;
}

a:hover {
    text-decoration: none !important;
}

.triangle {
    position: relative;
    background: #ffffff;
    height: 25rem;
}

.triangle::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 50vw 0 50vw;
    border-color: #ffffff transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    z-index: 1;
}

.triangleUp {
    position: relative;
    background: #ffffff;
    height: 0vh;
  }
  
.triangleUp::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 490px 80px 490px;
    border-color: transparent transparent #fff transparent;
    left: 50%;
    transform: translateX(-50%);
}

.semiCircle {
    position: relative;
    background: #2c3e50;
    height: 0vh;
  }
  
.semiCircle::before {
    position: absolute;
    content: '';
    left: 50%;
    z-index: 0;
    width: 100vw;
    height: 10rem;
    border-radius: 50%;
    background: #fff;
    transform: translateX(-50%) translateY(80%);
    bottom: 0px;
}

.curved {
    position: relative;
    background: #ffffff;
    margin-top: 12rem;
    height: 8rem;
    border-bottom-left-radius: 70% 100%;
    border-bottom-right-radius: 70% 100%;
}

.curvedUp {
    position: relative;
    background: #ffffff;
    height: 0vh;
}
  
.curvedUp::after {
    content: '';
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 10vh;
}

@media(min-width: 601px) and (max-width: 960px) {
    .triangle::before {
        border-width: 5rem 20rem;
        bottom: 2px;
    }
}

@media(max-width: 600px) {
    .triangle::before {
        border-width: 45px 50vw 0 50vw;
    }
    .triangle {
        height: 20rem
    }
    .semiCircle::before {
        height: 5rem
    }
    .curved {
        margin-top: 5rem;
        height: 8rem;
        border-bottom-left-radius: 100% 50%;
        border-bottom-right-radius: 100% 50%;
    }
    
    .curvedUp::after {
        height: 5vh
    }
}